
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import ChartStatistic from "@/views/components/chart-statistic.vue";
import EndpointsResponseModel from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Response/EndpointsResponseModel";
import ChartDataModel from "@/views/components/chartDataModel";
import ChartDatsetsModel from "@/views/components/chartDatasetsModel";
import EndpointUsersStatisticResponse from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Response/EndpointUsersStatisticResponse";
import EndpointUsersStatisticRequest from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Request/EndpointUsersStatisticRequest";
import FilterModel from "../../components/filter-model";
import getUTC from "@/function/getUTC"

@Options({
  name: "UserStatistic",
  components: {
    Page,
    ChartStatistic,
  },
})
export default class UserStatistic extends Vue {
  filter = new FilterModel();
  endpointsResponse: Array<EndpointsResponseModel> = [];
  endpointUsersStatisticRequest = new EndpointUsersStatisticRequest();
  flag = false;
  name: string = "";
  isLoading = false;

  isLiveReload = false;
  timerId = 0;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  datasets1 = new ChartDatsetsModel({
    label: "Колличество запросов",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });
  datasets2 = new ChartDatsetsModel({
    label: "Кол-во пользователей",
    data: [],
    borderColor: "#0000FF",
    backgroundColor: "#0000FF",
    yAxisID: "y1",
    pointStyle: "circle",
  });

  chartModel = new ChartDataModel({
    labels: [],
    datasets: [this.datasets1, this.datasets2],
  });

  async mounted() {
    this.filter.DateFrom = new Date(this.formatDate(this.$route.params.DateFrom))
    this.filter.DateTo = new Date(this.formatDate(this.$route.params.DateTo))
    this.name = this.$route.params.Name.toString();
    this.filter.interval = 1000;
    await this.getData();
  }

  unmounted() {
    clearInterval(this.timerId)
  }

  liveReload(value) {
    console.log("liveReload", value)
    this.isLiveReload = value
    if (value) {
      this.timerId = setInterval(() => {
        this.filter.DateTo = new FilterModel().DateTo
        this.getData()
      }, 5000)
    } else {
      clearInterval(this.timerId)
    }
  }

  formatDate(stringDate: string | any): string {
    let index = stringDate.indexOf(" (");
    if (~index) {
      return stringDate.substr(0, index);
    }
  }

  async getData() {
    this.isLoading = true;
    let res: EndpointUsersStatisticResponse[];
    this.endpointUsersStatisticRequest = {
      EndpointId: Number(this.$route.params.Id),
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Period: this.filter.interval,
    };
    let tmpFilter = getUTC(this.endpointUsersStatisticRequest)
    try {
      res =
        await this.$api.AnalyticEndpointsService.getEndpointUsersStatisticAsync(
            tmpFilter
        );
      this.flag = true;
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
    this.chartModel.labels.splice(0, this.chartModel.labels.length);
    this.datasets1.data.splice(0, this.chartModel.labels.length);
    this.datasets2.data.splice(0, this.chartModel.labels.length);
    res.forEach((elem) => {
      this.chartModel.labels.push(elem.TimePoint.toString());
      this.datasets1.data.push(elem.CallsCount);
      this.datasets2.data.push(elem.UsersCount);
    });
  }
}
