import EnumPeriodForAnalytic from '../../../../Common/Models/Enums/EnumPeriodForAnalytic';

export default class EndpointUsersStatisticRequest  {
	EndpointId: number = 0;
	FromDate: Date = null;
	ToDate: Date = null;
	Period: EnumPeriodForAnalytic = null;
	constructor(obj?: Partial<EndpointUsersStatisticRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
